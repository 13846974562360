/*

Neumorphic Checkbox Dark&Light Mode
https://codepen.io/DivineBlow/pen/KKgBPxY

*/

@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");

/* Light Mode styles */

.experience-font {
  box-sizing: border-box;
}

.experience-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
  flex-wrap: wrap;

  .pseudo-experience-card {
    height: 2px !important;
  }

  .experience-card {
    position: relative;
    width: 320px;
    height: 440px;
    outline: 1px solid #c770f0;
    border-radius: 15px;
    // margin: 30px;

    .box {
      position: absolute;
      top: 20px;
      right: 20px;
      bottom: 20px;
      left: 20px;
      background: transparent;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.5s;

      .content {
        padding: 20px;
        text-align: center;

        .fab {
          position: absolute;
          top: 0px;
          right: 20px;
          font-size: clamp(55px, 10vw, 65px);
          pointer-events: none;
        }

        .fa-python {
          background: rgb(0, 115, 163);
          background: linear-gradient(
            90deg,
            rgba(0, 115, 163, 1) 0%,
            rgba(255, 210, 41, 1) 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-fill-color: transparent;
        }

        h3 {
          padding-top: 60px;
          font-size: 1.2em;
          font-weight: 700;
          color: #fff;
          z-index: 1;
          transition: 0.5s;
          text-align: left;
        }

        h4 {
          // padding-top: 60px;
          font-size: 0.8em;
          font-weight: 600;
          color: #b3c5d1;
          z-index: 1;
          transition: 0.5s;
          text-align: left;
        }

        p {
          font-size: 0.95em;
          font-weight: 400;
          color: #b3c5d1;
          z-index: 1;
          transition: 0.5s;
          text-align: left;
        }

        a {
          position: relative;
          display: inline-block;
          padding: 8px 20px;
          background: #409eff;
          margin-top: 15px;
          border-radius: 20px;
          color: #fff;
          text-decoration: none;
          box-shadow: 0 0px 0px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }

  // @media (min-width: 1200px) {
  //   .card {
  //     width: 345px;
  //     height: 515x;
  //   }
  // }

  .experience-card:hover .box .content a {
    background: #ff4049;
  }
}

.experience-container .experience-card:hover .box {
  transform: translateY(-50px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4);
  background: linear-gradient(45deg, #a1ffce, #faffd1);
}

.experience-container .experience-card:hover .box .content h3,
.experience-container .experience-card:hover .box .content h4,
.experience-container .experience-card:hover .box .content p {
  color: #565656;
}

/* Dark Mode styles */

body.dark-theme {
  background: #232427;

  .experience-card {
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
      inset -5px -5px 15px rgba(255, 255, 255, 0.1),
      5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);

    .box {
      background: #2a2b2f;
      box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);

      .content h3 {
        color: rgba(255, 255, 255, 0.9);
      }

      .content h4 {
        color: rgba(255, 255, 255, 0.9);
      }

      .content p {
        color: rgba(255, 255, 255, 0.9);
      }

      .content a {
        background: #000;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
      }
    }
  }

  .experience-container .experience-card:nth-child(1) .box .content a {
    background: #ff0000;
  }

  .experience-container .experience-card:nth-child(2) .box .content a {
    background: #ba00ec;
  }

  .experience-container .experience-card:nth-child(3) .box .content a {
    background: #4158d0;
  }

  .experience-container .experience-card:hover .box {
    background-color: #4158d0;
    background-image: linear-gradient(
      43deg,
      #ffcc70 0%,
      #c850c0 46%,
      #4158d0 100%
    );
  }
}
