@import url("https://fonts.googleapis.com/css?family=Nunito:200,400,400i,600,700,800");
@import url(https://fonts.googleapis.com/css?family=Kaushan+Script);

.blog-card-wrapper {
    text-align: left !important;
}

.blog-card {
	display: block;
	position: relative;
	background: transparent;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat !important;
	width: 85%;
	max-width: 400px;
	// height: 350px;
	outline: 1px solid #c770f0;
	box-shadow: 4px 8px 28px rgba(39,44,49,.1), 1px 3px 8px rgba(39,44,49,.075);
	margin: 5vh auto;
	overflow: hidden !important;
	&:hover {
		box-shadow: 0 0 1px rgba(39,44,49,.2), 0 3px 16px rgba(39,44,49,.25);
		transform: translate3D(0,-.5px,0);
	}
	.content-mask {
		// position: fixed;
		display: inline-block;
		// background: rgba(#fff, 0.85);
		background: transparent;
		width: 100%;
		height: 100%;
		padding: 0.75em 0.5em 0.75em 1.5em;
		overflow: hidden;
		z-index: 5;
	}
	.horizontal {
		display: inline-block;
		position: relative;
		background: linear-gradient(to top right, rgba(#fff, 0.9) 50%, transparent 0);
		height: 101%;
		width: 20%;
		top: -5%;
		// left: -0.4vw;
		right: 0.8%;
		overflow: hidden;
	}
}

// .blog-card {
// 	&.web {
// 		background-image: url("https://blog.planview.com/wp-content/uploads/2020/01/Top-6-Software-Development-Methodologies.jpg");
// 	}
// 	&.data-science {
// 		background-image: url("https://www.sciencealert.com/images/2020-10/processed/artificial_intelligence_topic_1024.jpg");
// 	}
// }

.content-container {
	margin-right: -5vw;
}

.category {
	display: inline-block;
	background: #e23f7e;
	color: rgba(#fff, 0.9);
	font-weight: 500;
    font-size: 0.75em !important;
	letter-spacing: 1px;
	text-align: center;
	min-width: 45px;
	border-radius: 5px;
	box-shadow: 0 0 1px rgba(#ac1c38,.1), 0 2px 6px rgba(#ac1c38,.175);
	margin: 0vh 2px 2.5vh; // t r b l
	padding: 6px 8px;
	cursor: pointer;
	z-index: 9;
	&.tensorflow {
		background: #f78100;
	}
	&.keras {
		background: #d00101;
	}
	&.pytorch {
		background: #ee4d2d;
	}
	&.hadoop {
		background: #ffff01;
		color: rgba(#000, 1);
	}
	&.spark {
		background: #e16b1b;
	}
	&.airflow {
		background: #3babfe;
	}
	&.python {
		background: #2a9d5a;
	}
	&.neural-networks {
		background: #1c3d7f;
	}
	&.machine-learning {
		background: #3babfe;
	}
	&.html {
		background: #e35027;
	}
	&.sass {
		background: #cc6799;
	}
	&.css {
		background: #1673b6;
	}
	&.javascript {
		background: #f7df1f;
		color: rgba(#000, 1);
	}
	&.typescript {
		background: #3279c6;
	}
	&.react {
		background: #62dafb;
		color: rgba(#000, 1);
	}
	&.vue-js {
		background: #3fb27f;
	}
	&.nodejs {
		background: #349934;
	}
	&.ruby-on-rails {
		background: #cc0000;
	}
	&.django {
		background: #0d4c34;
	}
	&.flask {
		background: #a3abaf;
	}
	&.android-sdk {
		background: #3edc84;
		color: rgba(#000000, 1);
		font-weight: 900;
	}
	&.gcp {
		background: #4385f4;
	}
	&.aws {
		background: #ff9901;
	}
	&.heroku {
		background: #3e0094;
	}
	&.docker {
		background: #1588c6;
	}
	&.postgresql {
		background: #799eba;
	}
	&.nlp {
		background: #084ebc;
	}
	&.computer-vision {
		background: #00ff80;
	}
	&.sentiment-analysis {
		background: #1c3d7f;
	}
	&.text-classification {
		background: #3e8dce;
	}
	&.data-pipeline{
		background: #011f2e;
	}
	&.firestore{
		background: #f7c60e;
		color: rgba(#000, 1);
	}
	&.big-data{
		background: #b3c5d1;
		color: rgba(#000, 1);
	}
	&.matlab{
		background: #c05708;
	}
	&:hover {
		box-shadow: 0 0 1px rgba(#0a603a,.2), 0 3px 6px rgba(39,44,49,.25);
	}
}

.category h1 {
	background: transparent;
	color: rgba(#000, 0.9);
	font-family: 'Nunito', 'Open Sans', sans-serif;
	font-size: 2.5em;
	font-weight: 800;
	line-height: 1.2;
	height: auto;
	border-bottom: 2px solid rgba(#ccc, 0.75);
	margin-top: 0;
	margin-bottom: 0.35em;
	padding-bottom: 5px;
}

.category p {
	font-size: 1.1em;
	line-height: 1.4;
	width: 100%;
	margin-top: 0.3em; 
	// margin-right: -5vw;
	padding-left: 2px;
	z-index: 9;
}

.post-detail {
	color: rgba(#000, 0.55);
	margin-top: 6.5vh;
	padding-left: 2px;
	vertical-align: middle;
	.icon svg {
		position: relative;
		width: 14px;
		top: 2.25px;
		margin-right: 7px;
	}
	.date {
		color: rgba(#000, 0.65);
		font-size: 0.85em;
	}
}

.credit {
	position: relative;
	font-family: 'Nunito' Helvetica, sans-serif;
	font-size: 1.5em;
	font-weight: 200;
	text-align: center;
	bottom: 0.75em;
	margin-bottom: 10vh;
	.creator-link {
		color: #000;
		font-family: "Kaushan Script", "Lato", Helvetica, sans-serif;
		font-size: 1.05em;
		font-weight: 600;
		letter-spacing: 0.5px;
		text-decoration: none;
		transition: all 300ms linear;
		&:hover {
			color: #FFF;
			text-shadow: 0 1px 3px rgba(#000, 0.6);
			transition: all 350ms linear;
		}
	}
}